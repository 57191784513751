<template>
  <div class="home-main d-flex">
    <v-row>
      <v-col cols="8" class="w-70 mx-auto">
        <div
          class="
            d-flex
            main-height
            flex-column
            align-center
            justify-center
            flex-grow-1
          "
          :class="$vuetify.breakpoint.smAndUp ? 'pa-3' : ''"
        >
          <img lazy-src="logo" :src="topLogo" class="mb-2" />

          <div
            class="
              font-weight-bold
              text-h5
              d-flex
              align-center
              flex-wrap
              justify-center
              text-center
            "
          >
            <span class="font-weight-bold mb-2"
              >Custom AI chatbot's <br />
              trained on your data.
            </span>
          </div>

          <span
            class="text-center font-weight-medium"
            :style="
              $vuetify.breakpoint.smAndUp ? 'width: 500px' : 'width:340px'
            "
            >Zero coding. Share your trained chatbot in minutes.</span
          >

          <div
            class="mt-3"
            :class="$vuetify.breakpoint.smAndUp ? 'd-flex' : 'text-center'"
          >
            <v-btn
              class="mx-2"
              color="quaternary"
              rounded
              :to="hasAuthUser ? '/dashboard/playground' : '/sign-in'"
              style="color: #fff !important"
              >Dashboard</v-btn
            >
            <v-btn class="mx-2" text rounded>No credit card required</v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import HistoryComponent from "@/components/chat/History";
import topLogoImage from "@/assets/logos/pinoybot-200px.png";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Home",

  // components: { HistoryComponent },

  data() {
    return {};
  },

  computed: {
    ...mapGetters("auth", ["currentUser"]),

    topLogo() {
      return topLogoImage;
    },

    hasAuthUser() {
      return this.currentUser ? true : false;
    },
  },

  methods: {
    ...mapActions("bots", ["getBots"]),
  },

  watch: {},

  mounted() {
    // this.getBots();
  },
};
</script>

<style lang="scss" scoped>
.home-main {
  background: white;
}
.main-height {
  height: calc(100vh - 56px);
  max-height: calc(100vh - 56px);
}

.w-70 {
  width: 70%;
}
</style>
